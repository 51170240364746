.claim-reward {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding: 20px;
  background-color: #1e1e1e;
  border-radius: 10px;
  color: white;
}

.claim-reward h3 {
  margin-bottom: 20px;
  font-size: 24px;
}

.pool-selection,
.receiver-address,
.available-reward {
  margin-bottom: 20px;
}

label {
  display: block;
  margin-bottom: 5px;
  color: #888;
}

select,
input {
  width: 100%;
  padding: 10px;
  background-color: #2d2d2d;
  border: 1px solid #444;
  border-radius: 5px;
  color: white;
}

.address-input-container {
  display: flex;
  align-items: stretch;
}

.address-input-container input,
.address-input-container .fetch-button {
  height: 36px; /* Adjust this value to your preferred height */
  line-height: 36px; /* Match this to the height for vertical centering */
  padding-top: 0;
  padding-bottom: 0;
}

.address-input-container input {
  padding-left: 10px; /* Adjust horizontal padding as needed */
}

.address-input-container .fetch-button {
  padding: 0 15px; /* Adjust horizontal padding as needed */
  font-size: 14px; /* Adjust font size if necessary */
}

.fetch-button:hover {
  background-color: #008c5a;
}

.available-reward {
  background-color: #2d2d2d;
  border-radius: 5px;
  padding: 10px;
}

.reward-amount {
  font-size: 18px;
  font-weight: bold;
  color: #00a86b;
}

.claim-button {
  background-color: #00a86b;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.claim-button:hover {
  background-color: #008c5a;
}

.claim-button:disabled {
  background-color: #555;
  cursor: not-allowed;
}

.card {
  background-color: #1e1e1e;
  border-radius: 8px;
  padding: 12px;
  margin-top: 15px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.2);
  color: #fff;
  display: flex;
  flex-direction: column;
  max-width: 450px; /* Adjust this value as needed */
  margin-left: auto;
  margin-right: auto;
  margin-bottom: -40px;
}

.card h3 {
  margin-top: 0;
  margin-bottom: 8px;
  font-size: 16px;
}

.card p {
  margin: 0;
  font-size: 14px;
}

.card.error {
  border-left: 5px solid #ff4c4c;
}

.card.success {
  border-left: 5px solid #4caf50;
}
.notification {
  display: none;
  position: fixed;
  left: 50%;
  bottom: 20px;
  transform: translateX(-50%);
  background-color: #181414;
  color: white;
  padding: 15px;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  margin-bottom: 50px;
}

.notification.show {
  display: block;
}

.claim-button.disabled {
  background-color: #555;
  cursor: not-allowed;
}

@media (max-width: 768px) {
  .claim-reward {
    padding: 15px;
  }

  .address-input-container {
    flex-direction: column;
  }

  .address-input-container input,
  .address-input-container .fetch-button {
    width: 100%;
    height: auto;
    line-height: normal;
  }

  .address-input-container input {
    border-bottom: none;
    border-radius: 5px 5px 0 0;
  }

  .address-input-container .fetch-button {
    border-radius: 0 0 5px 5px;
  }

  .card {
    max-width: 100%;
  }
}